import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { PageListingResponse } from '@models/api/page-listing-response.model';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { User } from '@models/users/user.model';
import { UpdateUserRequest } from '@models/users/dto/update-user.request';
import { NewUserCreationRequest } from '@models/users/dto/new-user-creation.request';
import { UserProfileInfo } from '@models/users/dto/user-profile-info.response';

@Injectable()
export class UserService {
  constructor(private api: ApiService) {}

  getUserDetailsPaged(
    filters: Map<string, string>,
    pageNumber: number = 1,
    pageSize: number = 10,
  ): Observable<PageListingResponse<User>> {
    return this.api
      .getPaged(`${env.api}/users/paged`, pageNumber, pageSize, filters)
      .pipe(map((it) => this.mapUserDetails(it)));
  }

  private mapUserDetails(res: PageListingResponse<User>) {
    return {
      total: res.total,
      records: res.records.map((it) => new User(it)),
    };
  }

  getPartners(): Observable<User[]> {
    return this.api.get<User[]>(`${env.api}/users/partners`);
  }

  register(
    email?: string,
    password?: string,
    name?: string,
    referralCode?: string,
  ): Observable<User> {
    return this.api
      .post<User>(`${env.api}/register`, {
        email,
        password,
        name,
        referralCode,
      })
      .pipe(map((it) => new User(it)));
  }

  registerWithPhone(
    phone?: string,
    name?: string,
    referralCode?: string,
  ): Observable<User> {
    return this.api
      .post<User>(`${env.api}/register`, { phone, name, referralCode })
      .pipe(map((it) => new User(it)));
  }

  update(req: UpdateUserRequest): Observable<User> {
    return this.api
      .put<User>(`${env.api}/users`, req)
      .pipe(map((it) => new User(it)));
  }

  findByEmailOrPhone(req: Map<string, any>): Observable<User> {
    return this.api
      .get(`${env.api}/users/find-by-credentials`, req)
      .pipe(map((it) => new User(it)));
  }

  createOrFindUser(req?: NewUserCreationRequest): Observable<number> {
    return this.api.post<number>(`${env.api}/users/create-or-find-user`, req);
  }

  findUser(req?: NewUserCreationRequest): Observable<number> {
    return this.api.post<number>(`${env.api}/users/find-user`, req);
  }

  delete(userId: number): Observable<void> {
    return this.api.delete(`${env.api}/users/${userId}`);
  }

  getUserProfileInfo(userId: number): Observable<UserProfileInfo> {
    return this.api.get<UserProfileInfo>(
      `${env.api}/users/${userId}/profile-info`,
    );
  }

  getReferredUsers(userId: number): Observable<User[]> {
    return this.api
      .get<User[]>(`${env.api}/users/${userId}/referred-users`)
      .pipe(map(this.mapUsers));
  }

  checkReferralCodeExists(referralCode: string): Observable<boolean> {
    return this.api.get<boolean>(
      `${env.api}/users/check-referral-code/${referralCode}`,
    );
  }

  private mapUsers(res: User[]) {
    return res?.map((it) => new User(it));
  }
}
